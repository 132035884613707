import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import 'bootstrap/dist/js/bootstrap';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

const ContactUs = () => {
  const form = useRef();
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('default_service', 'template_v0c6iay', form.current, 'user_zB41AgS5iPYqBpY0wL8xj')
      .then((result) => {
          setShow(true);
          form.current.reset();
      }, (error) => {
          setShowError(true);
      });
  };

  return (
    <div>
        {
          show &&
            <Alert variant="success">
              Email successfully sent!
            </Alert>
        }
        {
          showError &&
            <Alert variant="danger">
              An error occured sending the email!
            </Alert>
        }
        <form ref={form} onSubmit={sendEmail}>
            <Row>
                <Col md="6" lg="4">
                    <div className="form-group wow fadeInUp" data-wow-delay="100ms">
                        <input type="text" name="from_name" className="form-control" id="name" placeholder="Name" />
                    </div>
                </Col>
                <Col md="6" lg="4">
                    <div className="form-group wow fadeInUp" data-wow-delay="200ms">
                        <input type="email" name="from_email" className="form-control" id="email" placeholder="E-mail" />
                    </div>
                </Col>
                <Col md="12" lg="12">
                    <div className="form-group wow ssfadeInUp" data-wow-delay="400ms">
                            <textarea name="message" className="form-control" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                    </div>
                </Col>
                <Col className="text-center wow fadeInUp" data-wow-delay="500ms">
                    <button className="btn oneMusic-btn mt-30" type="submit">Send <i className="fa fa-angle-double-right"></i></button>
                </Col>

            </Row>
        </form>
      </div>
  );
};

export default ContactUs
