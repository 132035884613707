import React, { Component } from "react";
import { getSongs } from "../services/song.db.storage";
import { transformUrl } from "../helpers/imagekit";
import _ from "lodash";

class Songs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playingDict: [],
      albums: [],
    };
  }

  audio = new Audio();

  async componentDidMount() {
    const songs = await getSongs(this.props.type);
    const albums = _(songs)
      .groupBy("album")
      .map((value, key) => ({ title: key, songs: value }))
      .orderBy((a) => a.songs.length, ["desc"])
      .value();

    this.setState({ albums: albums });
  }

  playAudio = (song) => {
    var playingDict = this.state.playingDict;
    for (var key in playingDict) {
      if (key !== song.title) {
        playingDict[key] = false;
      }
    }
    playingDict[song.title] = true;
    this.setState({ playingDict: playingDict });
    this.audio.src = song.songUrl;
    this.audio.play();
  };

  pauseAudio = (song) => {
    var playingDict = this.state.playingDict;
    for (var key in playingDict) {
      playingDict[key] = false;
    }
    this.setState({ playingDict: playingDict });
    this.audio.pause();
  };

  playing = (song) => {
    return this.state.playingDict[song.title] ?? false;
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div class="col-12">
            <div class="section-heading style-2 m-5">
              <h2>{this.props.type + "s"}</h2>
              <p>Like what you hear? <a href="#contactArea">Get in touch for licenses.</a></p>
            </div>
          </div>
          {this.state.albums.map((album) => (
            <div className="col-lg col-lg-6">
              <div className={"new-hits-area mb-100"} >
                <div
                  className="section-heading text-left mb-50 wow fadeInUp"
                  data-wow-delay="50ms"
                >
                  <p>See what's new</p>
                  <h2>{album.title}</h2>
                </div>
                {album.songs.map((song) => (
                  <div
                    className="single-new-item d-flex align-items-center justify-content-between wow fadeInUp"
                    data-wow-delay="100ms"
                  >
                    <div className="first-part d-flex align-items-center">
                      <div className="thumbnail">
                        <img src={transformUrl(song.imageUrl, 73, 73)} alt="" />
                      </div>
                      <div className="content-">
                        <h6>{song.title}</h6>
                        <p>{song.description}</p>
                      </div>
                    </div>
                    <a
                      data-animation="fadeInUp"
                      data-delay="500ms"
                      href="#songsArea"
                      onClick={() =>
                        this.playing(song)
                          ? this.pauseAudio(song)
                          : this.playAudio(song)
                      }
                    >
                      <i
                        className={
                          this.playing(song)
                            ? "fa fa-pause-circle"
                            : "fa fa-play-circle"
                        }
                        style={{ color: "grey", fontSize: "40px" }}
                      ></i>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Songs;
