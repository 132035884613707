import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDs6CRSHcCM5eUY4DTiaCOG7gUAYhwQbpM",
  authDomain: "songapp-9a9fc.firebaseapp.com",
  projectId: "songapp-9a9fc",
  storageBucket: "songapp-9a9fc.appspot.com",
  messagingSenderId: "981266694857",
  appId: "1:981266694857:web:50673c8c8251cf329d4318"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
