import firebaseApp from "./firebase.app";
import { getFirestore, collection, query, getDocs, where } from "firebase/firestore"

const firestore = getFirestore(firebaseApp);

const songsCollection = collection(firestore, "songs")

export const getSongs = async (type) => {
  const songsQuery = query(songsCollection, where("type", "==", type))
  const snapshot = await getDocs(songsQuery)
  return snapshot.docs.map(doc => doc.data())
}
