import React from 'react';
import ReactDOM from 'react-dom';
import Albums from './components/Albums';
import Contact from './components/Contact';
import reportWebVitals from './reportWebVitals';

import "./style.css"

ReactDOM.render(
  <React.StrictMode>
    <Albums type="Beat"/>
  </React.StrictMode>,
  document.getElementById("beats")
);

ReactDOM.render(
  <React.StrictMode>
    <Albums type="Song" bgColor="bg-gray"/>
  </React.StrictMode>,
  document.getElementById("songs")
);

ReactDOM.render(
  <React.StrictMode>
    <Contact />
  </React.StrictMode>,
  document.getElementById("contact")
);
reportWebVitals();
